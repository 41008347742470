import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  copyToClipboard(value: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.snackBar.open('Copied to clipboard.', '', {
      duration: 3000,
    });
  }

  share(channel: string, url: string): void {
    const shareUrl = url;
    if (channel === 'twitter') {
      window.open(`http://twitter.com/share?url=${shareUrl}&via=lofiearth`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    } else if (channel === 'facebook') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');

    }
  }
}
