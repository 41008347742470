import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Polaroid {
  created?: any;
  timestamp?: number;
  image: string;
  latitude: number;
  longitude: number;
  zoom: number;
  heading: number;
  id: string;
  uid: string;
}

@Injectable({
  providedIn: 'root'
})
export class CamerarollService {
  roll: Polaroid[];

  constructor(
    private snackBar: MatSnackBar,
  ) {
    const rawroll = localStorage.getItem('cameraroll');
    if (rawroll) {
      this.roll = JSON.parse(rawroll);
    } else {
      this.roll = [];
    }
  }

  add(photo: Polaroid): void {
    const lastPhoto = this.get()[0];
    if (lastPhoto && lastPhoto?.id === photo?.id) {
      return;
    }
    photo.timestamp = (new Date()).getTime();
    this.roll.unshift(photo);
    localStorage.setItem('cameraroll', JSON.stringify(this.roll));
  }

  get(): Polaroid[] {
    return this.roll;
  }

  delete(index: number): void {
    const deletedPhoto = this.roll[index];
    this.roll.splice(index, 1);
    localStorage.setItem('cameraroll', JSON.stringify(this.roll));
    const snackBarRef = this.snackBar.open('Deleted from camera roll.', 'UNDO', {
      duration: 7000,
    });
    snackBarRef.onAction().subscribe(() => {
      this.roll.splice(index, 0, deletedPhoto);
      localStorage.setItem('cameraroll', JSON.stringify(this.roll));
    });
  }
}
