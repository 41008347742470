<style>
    .logo {
        font-family: Sacramento;
        font-size: 100px;
        line-height: 110px;
    }

    blockquote {
        background: #f9f9f9;
        border-left: 5px solid #ccc;
        margin: 15px 0px;
        padding: 1em 10px;
        quotes: "\201C""\201D""\2018""\2019";
        white-space: pre-line;
    }
</style>

<div style="margin: 0 auto; max-width: 700px">
    <div style="margin: 15px">
        <div style="text-align: right">
            <div (click)="dismiss()" style="cursor: pointer; color: #448aff">
                Close
            </div>
        </div>
        <div class="logo">
            lofi.earth
        </div>
        <div style="margin: 30px 0px 80px 0px">
            <div style="font-size: 24px; line-height: 26px; font-family: Alatsi">
                Chill to lo-fi hip hop and let your curiosity roam the earth.
            </div>
            <div style="margin-top: 30px; font-size: 22px">
                FAQ
            </div>
            <ul style="padding-left: 25px">
                <li>
                    <div style="font-size: 18px">
                        Why did you create this?<br>
                    </div>
                    <div style="margin-top: 7px; font-size: 16px">
                        As we stay at home during this pandemic, I wanted to find a way to bring back that sense of
                        adventure, some way to travel the world while we shelter-in-place. We often use maps to navigate
                        from place to place, but they are also a great tool for discovery. You can use maps to find
                        places to visit or reminisce about where you've been. As I've seen many times over the years
                        with Google Earth, people love to just look at satellite imagery and so many map enthusiasts
                        have spent countless hours virtually traveling the earth. This is an attempt to re-introduce
                        some of that magic back into our lives to fill in the void that has been created by not being
                        able to travel anywhere for over a year.
                    </div>
                </li>
                <li style="margin-top: 15px">
                    <div style="font-size: 18px">
                        Where are the borders and labels?<br>
                    </div>
                    <div style="margin-top: 7px; font-size: 16px">
                        If you look at the earth from space, you don't see any borders. I really like this idea of a
                        borderless earth, it creates the spirit that we are all in this together. It also helps you
                        discover new places on the planet. You don't gravitate to the names and places you know, you
                        just zoom into anything that catches your eye and see the world for what it is versus the
                        construct that we as humans have placed on it over the centuries. But if you really want to see
                        the borders and labels, you can turn them on by tapping on the map button.
                    </div>
                </li>
                <li style="margin-top: 15px">
                    <div style="font-size: 18px">
                        What is lo-fi hip hop?<br>
                    </div>
                    <div style="margin-top: 7px; font-size: 16px">
                        Music can transport you to another place. It can transform your mindspace. It can make you feel
                        emotions in a way that no other medium can. I wanted to transport people not only visually but
                        also emotionally, put them in a headspace to wander and be open to serendipity.<br>
                        <br>
                        I recently discovered lo-fi hip hop and it really put me into a state of mind where hours would
                        pass as I just roamed around the earth. It was the perfect aural backdrop for virtually
                        traveling the earth. It's hard to describe lo-fi hip hop, but <a
                            href="https://www.polygon.com/2018/2/8/16990594/youtube-lofi-hip-hop-gaming-livestream-anime-wolf-children"
                            target="_blank">
                            Polygon makes a decent attempt</a>:
                        <blockquote>
                            “Lofi Hip Hop” refers to a genre of music that mashes up traditional hip-hop and jazz
                            elements
                            to create an atmospheric, instrumental soundscape.
                        </blockquote>
                        I highly recommend checking out lo-fi hip hop. This is the music that you hear playing as you
                        roam around on Lo-fi Earth.
                    </div>
                </li>
                <li style="margin-top: 15px">
                    <div style="font-size: 18px">
                        What does the heatmap represent?<br>
                    </div>
                    <div style="margin-top: 7px; font-size: 16px">
                        Each time you move around it drops a breadcrumb of where you've been. We collect all of these
                        breadcrumbs from everybody to create the heatmap you see on Lo-fi Earth. It gives you a sense of
                        what others are checking out and it is a nice way to crowdsource what places might be
                        interesting to visit.
                    </div>
                </li>
                <li style="margin-top: 15px">
                    <div style="font-size: 18px">
                        What does the camera do?<br>
                    </div>
                    <div style="margin-top: 7px; font-size: 16px">
                        The camera takes virtual "polaroids" of the earth that you can share with your friends. The
                        photos are saved in a camera roll so you can access them again later. This provides a subtle
                        social element to Lo-fi Earth, a way to connnect through an artifact. It's something you might
                        have done in person long ago, sharing a photo or souvenir you bought when you visited another
                        country. So just as you virtually travel the world, you can virtually take photos and share them
                        on social media or other communication mediums.
                    </div>
                </li>
                <li style="margin-top: 15px">
                    <div style="font-size: 18px">
                        How do I get in touch?<br>
                    </div>
                    <div style="margin-top: 7px; font-size: 16px">
                        Please post a tweet or send a DM to <a href="https://twitter.com/lofiearth"
                            target="_blank">@lofiearth</a> on Twitter.
                    </div>
                </li>
            </ul>
        </div>
        <div style="margin: 20px; font-size: 12px; opacity: 0.65; text-align: center; line-height: 14px">
            Build date<br>
            {{buildDate()}}
        </div>
    </div>
</div>