import { Injectable, NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, RouterModule, Routes, Router } from '@angular/router';

import { HomeComponent } from './home/home.component';

@Injectable()
export class CanActivateUser implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
  ) { }

  async canActivate(): Promise<boolean> {
    if (!localStorage.getItem('authorization')) {
      await this.afAuth.signInAnonymously();
      localStorage.setItem('authorization', 'no');
    }
    this.afAuth.user.subscribe(user => {
      if (!(user && user !== null && !user.isAnonymous)) {
        if (!user || user === null) {
          localStorage.setItem('authorization', 'no');
          this.afAuth.signInAnonymously();
        }
      } else {
        localStorage.setItem('authorization', 'yes');
      }
    });
    return true;
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [CanActivateUser]
  },
  {
    path: 'go',
    component: HomeComponent,
    canActivate: [CanActivateUser]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    CanActivateUser
  ]
})
export class AppRoutingModule { }
