import { Injectable } from '@angular/core';

export interface Country {
  iso: string;
  latitude?: number;
  longitude?: number;
  emoji?: string;
  unicode?: string;
  name: string;
  name_alternate?: string;
}

const countries = [
  {
    iso: 'AD',
    latitude: 42.546245,
    longitude: 1.601554,
    emoji: '🇦🇩',
    unicode: 'U+1F1E6 U+1F1E9',
    name: 'Andorra',
    name_alternate: 'Andorra'
  },
  {
    iso: 'AE',
    latitude: 23.424076,
    longitude: 53.847818,
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
    name: 'United Arab Emirates',
    name_alternate: 'United Arab Emirates'
  },
  {
    iso: 'AF',
    latitude: 33.93911,
    longitude: 67.709953,
    emoji: '🇦🇫',
    unicode: 'U+1F1E6 U+1F1EB',
    name: 'Afghanistan',
    name_alternate: 'Afghanistan'
  },
  {
    iso: 'AG',
    latitude: 17.060816,
    longitude: -61.796428,
    emoji: '🇦🇬',
    unicode: 'U+1F1E6 U+1F1EC',
    name: 'Antigua and Barbuda',
    name_alternate: 'Antigua and Barbuda'
  },
  {
    iso: 'AI',
    latitude: 18.220554,
    longitude: -63.068615,
    emoji: '🇦🇮',
    unicode: 'U+1F1E6 U+1F1EE',
    name: 'Anguilla',
    name_alternate: 'Anguilla'
  },
  {
    iso: 'AL',
    latitude: 41.153332,
    longitude: 20.168331,
    emoji: '🇦🇱',
    unicode: 'U+1F1E6 U+1F1F1',
    name: 'Albania',
    name_alternate: 'Albania'
  },
  {
    iso: 'AM',
    latitude: 40.069099,
    longitude: 45.038189,
    emoji: '🇦🇲',
    unicode: 'U+1F1E6 U+1F1F2',
    name: 'Armenia',
    name_alternate: 'Armenia'
  },
  {
    iso: 'AN',
    latitude: 12.226079,
    longitude: -69.060087,
    name: 'Netherlands Antilles'
  },
  {
    iso: 'AO',
    latitude: -11.202692,
    longitude: 17.873887,
    emoji: '🇦🇴',
    unicode: 'U+1F1E6 U+1F1F4',
    name: 'Angola',
    name_alternate: 'Angola'
  },
  {
    iso: 'AQ',
    latitude: -75.250973,
    longitude: -0.071389,
    emoji: '🇦🇶',
    unicode: 'U+1F1E6 U+1F1F6',
    name: 'Antarctica',
    name_alternate: 'Antarctica'
  },
  {
    iso: 'AR',
    latitude: -38.416097,
    longitude: -63.616672,
    emoji: '🇦🇷',
    unicode: 'U+1F1E6 U+1F1F7',
    name: 'Argentina',
    name_alternate: 'Argentina'
  },
  {
    iso: 'AS',
    latitude: -14.270972,
    longitude: -170.132217,
    emoji: '🇦🇸',
    unicode: 'U+1F1E6 U+1F1F8',
    name: 'American Samoa',
    name_alternate: 'American Samoa'
  },
  {
    iso: 'AT',
    latitude: 47.516231,
    longitude: 14.550072,
    emoji: '🇦🇹',
    unicode: 'U+1F1E6 U+1F1F9',
    name: 'Austria',
    name_alternate: 'Austria'
  },
  {
    iso: 'AU',
    latitude: -25.274398,
    longitude: 133.775136,
    emoji: '🇦🇺',
    unicode: 'U+1F1E6 U+1F1FA',
    name: 'Australia',
    name_alternate: 'Australia'
  },
  {
    iso: 'AW',
    latitude: 12.52111,
    longitude: -69.968338,
    emoji: '🇦🇼',
    unicode: 'U+1F1E6 U+1F1FC',
    name: 'Aruba',
    name_alternate: 'Aruba'
  },
  {
    iso: 'AX',
    emoji: '🇦🇽',
    unicode: 'U+1F1E6 U+1F1FD',
    name: 'Åland Islands'
  },
  {
    iso: 'AZ',
    latitude: 40.143105,
    longitude: 47.576927,
    emoji: '🇦🇿',
    unicode: 'U+1F1E6 U+1F1FF',
    name: 'Azerbaijan',
    name_alternate: 'Azerbaijan'
  },
  {
    iso: 'BA',
    latitude: 43.915886,
    longitude: 17.679076,
    emoji: '🇧🇦',
    unicode: 'U+1F1E7 U+1F1E6',
    name: 'Bosnia and Herzegovina',
    name_alternate: 'Bosnia and Herzegovina'
  },
  {
    iso: 'BB',
    latitude: 13.193887,
    longitude: -59.543198,
    emoji: '🇧🇧',
    unicode: 'U+1F1E7 U+1F1E7',
    name: 'Barbados',
    name_alternate: 'Barbados'
  },
  {
    iso: 'BD',
    latitude: 23.684994,
    longitude: 90.356331,
    emoji: '🇧🇩',
    unicode: 'U+1F1E7 U+1F1E9',
    name: 'Bangladesh',
    name_alternate: 'Bangladesh'
  },
  {
    iso: 'BE',
    latitude: 50.503887,
    longitude: 4.469936,
    emoji: '🇧🇪',
    unicode: 'U+1F1E7 U+1F1EA',
    name: 'Belgium',
    name_alternate: 'Belgium'
  },
  {
    iso: 'BF',
    latitude: 12.238333,
    longitude: -1.561593,
    emoji: '🇧🇫',
    unicode: 'U+1F1E7 U+1F1EB',
    name: 'Burkina Faso',
    name_alternate: 'Burkina Faso'
  },
  {
    iso: 'BG',
    latitude: 42.733883,
    longitude: 25.48583,
    emoji: '🇧🇬',
    unicode: 'U+1F1E7 U+1F1EC',
    name: 'Bulgaria',
    name_alternate: 'Bulgaria'
  },
  {
    iso: 'BH',
    latitude: 25.930414,
    longitude: 50.637772,
    emoji: '🇧🇭',
    unicode: 'U+1F1E7 U+1F1ED',
    name: 'Bahrain',
    name_alternate: 'Bahrain'
  },
  {
    iso: 'BI',
    latitude: -3.373056,
    longitude: 29.918886,
    emoji: '🇧🇮',
    unicode: 'U+1F1E7 U+1F1EE',
    name: 'Burundi',
    name_alternate: 'Burundi'
  },
  {
    iso: 'BJ',
    latitude: 9.30769,
    longitude: 2.315834,
    emoji: '🇧🇯',
    unicode: 'U+1F1E7 U+1F1EF',
    name: 'Benin',
    name_alternate: 'Benin'
  },
  {
    iso: 'BL',
    emoji: '🇧🇱',
    unicode: 'U+1F1E7 U+1F1F1',
    name: 'Saint Barthélemy'
  },
  {
    iso: 'BM',
    latitude: 32.321384,
    longitude: -64.75737,
    emoji: '🇧🇲',
    unicode: 'U+1F1E7 U+1F1F2',
    name: 'Bermuda',
    name_alternate: 'Bermuda'
  },
  {
    iso: 'BN',
    latitude: 4.535277,
    longitude: 114.727669,
    emoji: '🇧🇳',
    unicode: 'U+1F1E7 U+1F1F3',
    name: 'Brunei Darussalam',
    name_alternate: 'Brunei'
  },
  {
    iso: 'BO',
    latitude: -16.290154,
    longitude: -63.588653,
    emoji: '🇧🇴',
    unicode: 'U+1F1E7 U+1F1F4',
    name: 'Bolivia',
    name_alternate: 'Bolivia'
  },
  {
    iso: 'BQ',
    emoji: '🇧🇶',
    unicode: 'U+1F1E7 U+1F1F6',
    name: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    iso: 'BR',
    latitude: -14.235004,
    longitude: -51.92528,
    emoji: '🇧🇷',
    unicode: 'U+1F1E7 U+1F1F7',
    name: 'Brazil',
    name_alternate: 'Brazil'
  },
  {
    iso: 'BS',
    latitude: 25.03428,
    longitude: -77.39628,
    emoji: '🇧🇸',
    unicode: 'U+1F1E7 U+1F1F8',
    name: 'Bahamas',
    name_alternate: 'Bahamas'
  },
  {
    iso: 'BT',
    latitude: 27.514162,
    longitude: 90.433601,
    emoji: '🇧🇹',
    unicode: 'U+1F1E7 U+1F1F9',
    name: 'Bhutan',
    name_alternate: 'Bhutan'
  },
  {
    iso: 'BV',
    latitude: -54.423199,
    longitude: 3.413194,
    emoji: '🇧🇻',
    unicode: 'U+1F1E7 U+1F1FB',
    name: 'Bouvet Island',
    name_alternate: 'Bouvet Island'
  },
  {
    iso: 'BW',
    latitude: -22.328474,
    longitude: 24.684866,
    emoji: '🇧🇼',
    unicode: 'U+1F1E7 U+1F1FC',
    name: 'Botswana',
    name_alternate: 'Botswana'
  },
  {
    iso: 'BY',
    latitude: 53.709807,
    longitude: 27.953389,
    emoji: '🇧🇾',
    unicode: 'U+1F1E7 U+1F1FE',
    name: 'Belarus',
    name_alternate: 'Belarus'
  },
  {
    iso: 'BZ',
    latitude: 17.189877,
    longitude: -88.49765,
    emoji: '🇧🇿',
    unicode: 'U+1F1E7 U+1F1FF',
    name: 'Belize',
    name_alternate: 'Belize'
  },
  {
    iso: 'CA',
    latitude: 56.130366,
    longitude: -106.346771,
    emoji: '🇨🇦',
    unicode: 'U+1F1E8 U+1F1E6',
    name: 'Canada',
    name_alternate: 'Canada'
  },
  {
    iso: 'CC',
    latitude: -12.164165,
    longitude: 96.870956,
    emoji: '🇨🇨',
    unicode: 'U+1F1E8 U+1F1E8',
    name: 'Cocos (Keeling) Islands',
    name_alternate: 'Cocos [Keeling] Islands'
  },
  {
    iso: 'CD',
    latitude: -4.038333,
    longitude: 21.758664,
    emoji: '🇨🇩',
    unicode: 'U+1F1E8 U+1F1E9',
    name: 'Congo',
    name_alternate: 'Congo [DRC]'
  },
  {
    iso: 'CF',
    latitude: 6.611111,
    longitude: 20.939444,
    emoji: '🇨🇫',
    unicode: 'U+1F1E8 U+1F1EB',
    name: 'Central African Republic',
    name_alternate: 'Central African Republic'
  },
  {
    iso: 'CG',
    latitude: -0.228021,
    longitude: 15.827659,
    emoji: '🇨🇬',
    unicode: 'U+1F1E8 U+1F1EC',
    name: 'Congo',
    name_alternate: 'Congo [Republic]'
  },
  {
    iso: 'CH',
    latitude: 46.818188,
    longitude: 8.227512,
    emoji: '🇨🇭',
    unicode: 'U+1F1E8 U+1F1ED',
    name: 'Switzerland',
    name_alternate: 'Switzerland'
  },
  {
    iso: 'CI',
    latitude: 7.539989,
    longitude: -5.54708,
    emoji: '🇨🇮',
    unicode: 'U+1F1E8 U+1F1EE',
    name: 'Côte D\'Ivoire',
    name_alternate: 'Côte d\'Ivoire'
  },
  {
    iso: 'CK',
    latitude: -21.236736,
    longitude: -159.777671,
    emoji: '🇨🇰',
    unicode: 'U+1F1E8 U+1F1F0',
    name: 'Cook Islands',
    name_alternate: 'Cook Islands'
  },
  {
    iso: 'CL',
    latitude: -35.675147,
    longitude: -71.542969,
    emoji: '🇨🇱',
    unicode: 'U+1F1E8 U+1F1F1',
    name: 'Chile',
    name_alternate: 'Chile'
  },
  {
    iso: 'CM',
    latitude: 7.369722,
    longitude: 12.354722,
    emoji: '🇨🇲',
    unicode: 'U+1F1E8 U+1F1F2',
    name: 'Cameroon',
    name_alternate: 'Cameroon'
  },
  {
    iso: 'CN',
    latitude: 35.86166,
    longitude: 104.195397,
    emoji: '🇨🇳',
    unicode: 'U+1F1E8 U+1F1F3',
    name: 'China',
    name_alternate: 'China'
  },
  {
    iso: 'CO',
    latitude: 4.570868,
    longitude: -74.297333,
    emoji: '🇨🇴',
    unicode: 'U+1F1E8 U+1F1F4',
    name: 'Colombia',
    name_alternate: 'Colombia'
  },
  {
    iso: 'CR',
    latitude: 9.748917,
    longitude: -83.753428,
    emoji: '🇨🇷',
    unicode: 'U+1F1E8 U+1F1F7',
    name: 'Costa Rica',
    name_alternate: 'Costa Rica'
  },
  {
    iso: 'CU',
    latitude: 21.521757,
    longitude: -77.781167,
    emoji: '🇨🇺',
    unicode: 'U+1F1E8 U+1F1FA',
    name: 'Cuba',
    name_alternate: 'Cuba'
  },
  {
    iso: 'CV',
    latitude: 16.002082,
    longitude: -24.013197,
    emoji: '🇨🇻',
    unicode: 'U+1F1E8 U+1F1FB',
    name: 'Cape Verde',
    name_alternate: 'Cape Verde'
  },
  {
    iso: 'CW',
    emoji: '🇨🇼',
    unicode: 'U+1F1E8 U+1F1FC',
    name: 'Curaçao'
  },
  {
    iso: 'CX',
    latitude: -10.447525,
    longitude: 105.690449,
    emoji: '🇨🇽',
    unicode: 'U+1F1E8 U+1F1FD',
    name: 'Christmas Island',
    name_alternate: 'Christmas Island'
  },
  {
    iso: 'CY',
    latitude: 35.126413,
    longitude: 33.429859,
    emoji: '🇨🇾',
    unicode: 'U+1F1E8 U+1F1FE',
    name: 'Cyprus',
    name_alternate: 'Cyprus'
  },
  {
    iso: 'CZ',
    latitude: 49.817492,
    longitude: 15.472962,
    emoji: '🇨🇿',
    unicode: 'U+1F1E8 U+1F1FF',
    name: 'Czech Republic',
    name_alternate: 'Czech Republic'
  },
  {
    iso: 'DE',
    latitude: 51.165691,
    longitude: 10.451526,
    emoji: '🇩🇪',
    unicode: 'U+1F1E9 U+1F1EA',
    name: 'Germany',
    name_alternate: 'Germany'
  },
  {
    iso: 'DJ',
    latitude: 11.825138,
    longitude: 42.590275,
    emoji: '🇩🇯',
    unicode: 'U+1F1E9 U+1F1EF',
    name: 'Djibouti',
    name_alternate: 'Djibouti'
  },
  {
    iso: 'DK',
    latitude: 56.26392,
    longitude: 9.501785,
    emoji: '🇩🇰',
    unicode: 'U+1F1E9 U+1F1F0',
    name: 'Denmark',
    name_alternate: 'Denmark'
  },
  {
    iso: 'DM',
    latitude: 15.414999,
    longitude: -61.370976,
    emoji: '🇩🇲',
    unicode: 'U+1F1E9 U+1F1F2',
    name: 'Dominica',
    name_alternate: 'Dominica'
  },
  {
    iso: 'DO',
    latitude: 18.735693,
    longitude: -70.162651,
    emoji: '🇩🇴',
    unicode: 'U+1F1E9 U+1F1F4',
    name: 'Dominican Republic',
    name_alternate: 'Dominican Republic'
  },
  {
    iso: 'DZ',
    latitude: 28.033886,
    longitude: 1.659626,
    emoji: '🇩🇿',
    unicode: 'U+1F1E9 U+1F1FF',
    name: 'Algeria',
    name_alternate: 'Algeria'
  },
  {
    iso: 'EC',
    latitude: -1.831239,
    longitude: -78.183406,
    emoji: '🇪🇨',
    unicode: 'U+1F1EA U+1F1E8',
    name: 'Ecuador',
    name_alternate: 'Ecuador'
  },
  {
    iso: 'EE',
    latitude: 58.595272,
    longitude: 25.013607,
    emoji: '🇪🇪',
    unicode: 'U+1F1EA U+1F1EA',
    name: 'Estonia',
    name_alternate: 'Estonia'
  },
  {
    iso: 'EG',
    latitude: 26.820553,
    longitude: 30.802498,
    emoji: '🇪🇬',
    unicode: 'U+1F1EA U+1F1EC',
    name: 'Egypt',
    name_alternate: 'Egypt'
  },
  {
    iso: 'EH',
    latitude: 24.215527,
    longitude: -12.885834,
    emoji: '🇪🇭',
    unicode: 'U+1F1EA U+1F1ED',
    name: 'Western Sahara',
    name_alternate: 'Western Sahara'
  },
  {
    iso: 'ER',
    latitude: 15.179384,
    longitude: 39.782334,
    emoji: '🇪🇷',
    unicode: 'U+1F1EA U+1F1F7',
    name: 'Eritrea',
    name_alternate: 'Eritrea'
  },
  {
    iso: 'ES',
    latitude: 40.463667,
    longitude: -3.74922,
    emoji: '🇪🇸',
    unicode: 'U+1F1EA U+1F1F8',
    name: 'Spain',
    name_alternate: 'Spain'
  },
  {
    iso: 'ET',
    latitude: 9.145,
    longitude: 40.489673,
    emoji: '🇪🇹',
    unicode: 'U+1F1EA U+1F1F9',
    name: 'Ethiopia',
    name_alternate: 'Ethiopia'
  },
  {
    iso: 'FI',
    latitude: 61.92411,
    longitude: 25.748151,
    emoji: '🇫🇮',
    unicode: 'U+1F1EB U+1F1EE',
    name: 'Finland',
    name_alternate: 'Finland'
  },
  {
    iso: 'FJ',
    latitude: -16.578193,
    longitude: 179.414413,
    emoji: '🇫🇯',
    unicode: 'U+1F1EB U+1F1EF',
    name: 'Fiji',
    name_alternate: 'Fiji'
  },
  {
    iso: 'FK',
    latitude: -51.796253,
    longitude: -59.523613,
    emoji: '🇫🇰',
    unicode: 'U+1F1EB U+1F1F0',
    name: 'Falkland Islands (Malvinas)',
    name_alternate: 'Falkland Islands [Islas Malvinas]'
  },
  {
    iso: 'FM',
    latitude: 7.425554,
    longitude: 150.550812,
    emoji: '🇫🇲',
    unicode: 'U+1F1EB U+1F1F2',
    name: 'Micronesia',
    name_alternate: 'Micronesia'
  },
  {
    iso: 'FO',
    latitude: 61.892635,
    longitude: -6.911806,
    emoji: '🇫🇴',
    unicode: 'U+1F1EB U+1F1F4',
    name: 'Faroe Islands',
    name_alternate: 'Faroe Islands'
  },
  {
    iso: 'FR',
    latitude: 46.227638,
    longitude: 2.213749,
    emoji: '🇫🇷',
    unicode: 'U+1F1EB U+1F1F7',
    name: 'France',
    name_alternate: 'France'
  },
  {
    iso: 'GA',
    latitude: -0.803689,
    longitude: 11.609444,
    emoji: '🇬🇦',
    unicode: 'U+1F1EC U+1F1E6',
    name: 'Gabon',
    name_alternate: 'Gabon'
  },
  {
    iso: 'GB',
    latitude: 55.378051,
    longitude: -3.435973,
    emoji: '🇬🇧',
    unicode: 'U+1F1EC U+1F1E7',
    name: 'United Kingdom',
    name_alternate: 'United Kingdom'
  },
  {
    iso: 'GD',
    latitude: 12.262776,
    longitude: -61.604171,
    emoji: '🇬🇩',
    unicode: 'U+1F1EC U+1F1E9',
    name: 'Grenada',
    name_alternate: 'Grenada'
  },
  {
    iso: 'GE',
    latitude: 42.315407,
    longitude: 43.356892,
    emoji: '🇬🇪',
    unicode: 'U+1F1EC U+1F1EA',
    name: 'Georgia',
    name_alternate: 'Georgia'
  },
  {
    iso: 'GF',
    latitude: 3.933889,
    longitude: -53.125782,
    emoji: '🇬🇫',
    unicode: 'U+1F1EC U+1F1EB',
    name: 'French Guiana',
    name_alternate: 'French Guiana'
  },
  {
    iso: 'GG',
    latitude: 49.465691,
    longitude: -2.585278,
    emoji: '🇬🇬',
    unicode: 'U+1F1EC U+1F1EC',
    name: 'Guernsey',
    name_alternate: 'Guernsey'
  },
  {
    iso: 'GH',
    latitude: 7.946527,
    longitude: -1.023194,
    emoji: '🇬🇭',
    unicode: 'U+1F1EC U+1F1ED',
    name: 'Ghana',
    name_alternate: 'Ghana'
  },
  {
    iso: 'GI',
    latitude: 36.137741,
    longitude: -5.345374,
    emoji: '🇬🇮',
    unicode: 'U+1F1EC U+1F1EE',
    name: 'Gibraltar',
    name_alternate: 'Gibraltar'
  },
  {
    iso: 'GL',
    latitude: 71.706936,
    longitude: -42.604303,
    emoji: '🇬🇱',
    unicode: 'U+1F1EC U+1F1F1',
    name: 'Greenland',
    name_alternate: 'Greenland'
  },
  {
    iso: 'GM',
    latitude: 13.443182,
    longitude: -15.310139,
    emoji: '🇬🇲',
    unicode: 'U+1F1EC U+1F1F2',
    name: 'Gambia',
    name_alternate: 'Gambia'
  },
  {
    iso: 'GN',
    latitude: 9.945587,
    longitude: -9.696645,
    emoji: '🇬🇳',
    unicode: 'U+1F1EC U+1F1F3',
    name: 'Guinea',
    name_alternate: 'Guinea'
  },
  {
    iso: 'GP',
    latitude: 16.995971,
    longitude: -62.067641,
    emoji: '🇬🇵',
    unicode: 'U+1F1EC U+1F1F5',
    name: 'Guadeloupe',
    name_alternate: 'Guadeloupe'
  },
  {
    iso: 'GQ',
    latitude: 1.650801,
    longitude: 10.267895,
    emoji: '🇬🇶',
    unicode: 'U+1F1EC U+1F1F6',
    name: 'Equatorial Guinea',
    name_alternate: 'Equatorial Guinea'
  },
  {
    iso: 'GR',
    latitude: 39.074208,
    longitude: 21.824312,
    emoji: '🇬🇷',
    unicode: 'U+1F1EC U+1F1F7',
    name: 'Greece',
    name_alternate: 'Greece'
  },
  {
    iso: 'GS',
    latitude: -54.429579,
    longitude: -36.587909,
    emoji: '🇬🇸',
    unicode: 'U+1F1EC U+1F1F8',
    name: 'South Georgia',
    name_alternate: 'South Georgia and the South Sandwich Islands'
  },
  {
    iso: 'GT',
    latitude: 15.783471,
    longitude: -90.230759,
    emoji: '🇬🇹',
    unicode: 'U+1F1EC U+1F1F9',
    name: 'Guatemala',
    name_alternate: 'Guatemala'
  },
  {
    iso: 'GU',
    latitude: 13.444304,
    longitude: 144.793731,
    emoji: '🇬🇺',
    unicode: 'U+1F1EC U+1F1FA',
    name: 'Guam',
    name_alternate: 'Guam'
  },
  {
    iso: 'GW',
    latitude: 11.803749,
    longitude: -15.180413,
    emoji: '🇬🇼',
    unicode: 'U+1F1EC U+1F1FC',
    name: 'Guinea-Bissau',
    name_alternate: 'Guinea-Bissau'
  },
  {
    iso: 'GY',
    latitude: 4.860416,
    longitude: -58.93018,
    emoji: '🇬🇾',
    unicode: 'U+1F1EC U+1F1FE',
    name: 'Guyana',
    name_alternate: 'Guyana'
  },
  {
    iso: 'GZ',
    latitude: 31.354676,
    longitude: 34.308825,
    name: 'Gaza Strip'
  },
  {
    iso: 'HK',
    latitude: 22.396428,
    longitude: 114.109497,
    emoji: '🇭🇰',
    unicode: 'U+1F1ED U+1F1F0',
    name: 'Hong Kong',
    name_alternate: 'Hong Kong'
  },
  {
    iso: 'HM',
    latitude: -53.08181,
    longitude: 73.504158,
    emoji: '🇭🇲',
    unicode: 'U+1F1ED U+1F1F2',
    name: 'Heard Island and Mcdonald Islands',
    name_alternate: 'Heard Island and McDonald Islands'
  },
  {
    iso: 'HN',
    latitude: 15.199999,
    longitude: -86.241905,
    emoji: '🇭🇳',
    unicode: 'U+1F1ED U+1F1F3',
    name: 'Honduras',
    name_alternate: 'Honduras'
  },
  {
    iso: 'HR',
    latitude: 45.1,
    longitude: 15.2,
    emoji: '🇭🇷',
    unicode: 'U+1F1ED U+1F1F7',
    name: 'Croatia',
    name_alternate: 'Croatia'
  },
  {
    iso: 'HT',
    latitude: 18.971187,
    longitude: -72.285215,
    emoji: '🇭🇹',
    unicode: 'U+1F1ED U+1F1F9',
    name: 'Haiti',
    name_alternate: 'Haiti'
  },
  {
    iso: 'HU',
    latitude: 47.162494,
    longitude: 19.503304,
    emoji: '🇭🇺',
    unicode: 'U+1F1ED U+1F1FA',
    name: 'Hungary',
    name_alternate: 'Hungary'
  },
  {
    iso: 'ID',
    latitude: -0.789275,
    longitude: 113.921327,
    emoji: '🇮🇩',
    unicode: 'U+1F1EE U+1F1E9',
    name: 'Indonesia',
    name_alternate: 'Indonesia'
  },
  {
    iso: 'IE',
    latitude: 53.41291,
    longitude: -8.24389,
    emoji: '🇮🇪',
    unicode: 'U+1F1EE U+1F1EA',
    name: 'Ireland',
    name_alternate: 'Ireland'
  },
  {
    iso: 'IL',
    latitude: 31.046051,
    longitude: 34.851612,
    emoji: '🇮🇱',
    unicode: 'U+1F1EE U+1F1F1',
    name: 'Israel',
    name_alternate: 'Israel'
  },
  {
    iso: 'IM',
    latitude: 54.236107,
    longitude: -4.548056,
    emoji: '🇮🇲',
    unicode: 'U+1F1EE U+1F1F2',
    name: 'Isle of Man',
    name_alternate: 'Isle of Man'
  },
  {
    iso: 'IN',
    latitude: 20.593684,
    longitude: 78.96288,
    emoji: '🇮🇳',
    unicode: 'U+1F1EE U+1F1F3',
    name: 'India',
    name_alternate: 'India'
  },
  {
    iso: 'IO',
    latitude: -6.343194,
    longitude: 71.876519,
    emoji: '🇮🇴',
    unicode: 'U+1F1EE U+1F1F4',
    name: 'British Indian Ocean Territory',
    name_alternate: 'British Indian Ocean Territory'
  },
  {
    iso: 'IQ',
    latitude: 33.223191,
    longitude: 43.679291,
    emoji: '🇮🇶',
    unicode: 'U+1F1EE U+1F1F6',
    name: 'Iraq',
    name_alternate: 'Iraq'
  },
  {
    iso: 'IR',
    latitude: 32.427908,
    longitude: 53.688046,
    emoji: '🇮🇷',
    unicode: 'U+1F1EE U+1F1F7',
    name: 'Iran',
    name_alternate: 'Iran'
  },
  {
    iso: 'IS',
    latitude: 64.963051,
    longitude: -19.020835,
    emoji: '🇮🇸',
    unicode: 'U+1F1EE U+1F1F8',
    name: 'Iceland',
    name_alternate: 'Iceland'
  },
  {
    iso: 'IT',
    latitude: 41.87194,
    longitude: 12.56738,
    emoji: '🇮🇹',
    unicode: 'U+1F1EE U+1F1F9',
    name: 'Italy',
    name_alternate: 'Italy'
  },
  {
    iso: 'JE',
    latitude: 49.214439,
    longitude: -2.13125,
    emoji: '🇯🇪',
    unicode: 'U+1F1EF U+1F1EA',
    name: 'Jersey',
    name_alternate: 'Jersey'
  },
  {
    iso: 'JM',
    latitude: 18.109581,
    longitude: -77.297508,
    emoji: '🇯🇲',
    unicode: 'U+1F1EF U+1F1F2',
    name: 'Jamaica',
    name_alternate: 'Jamaica'
  },
  {
    iso: 'JO',
    latitude: 30.585164,
    longitude: 36.238414,
    emoji: '🇯🇴',
    unicode: 'U+1F1EF U+1F1F4',
    name: 'Jordan',
    name_alternate: 'Jordan'
  },
  {
    iso: 'JP',
    latitude: 36.204824,
    longitude: 138.252924,
    emoji: '🇯🇵',
    unicode: 'U+1F1EF U+1F1F5',
    name: 'Japan',
    name_alternate: 'Japan'
  },
  {
    iso: 'KE',
    latitude: -0.023559,
    longitude: 37.906193,
    emoji: '🇰🇪',
    unicode: 'U+1F1F0 U+1F1EA',
    name: 'Kenya',
    name_alternate: 'Kenya'
  },
  {
    iso: 'KG',
    latitude: 41.20438,
    longitude: 74.766098,
    emoji: '🇰🇬',
    unicode: 'U+1F1F0 U+1F1EC',
    name: 'Kyrgyzstan',
    name_alternate: 'Kyrgyzstan'
  },
  {
    iso: 'KH',
    latitude: 12.565679,
    longitude: 104.990963,
    emoji: '🇰🇭',
    unicode: 'U+1F1F0 U+1F1ED',
    name: 'Cambodia',
    name_alternate: 'Cambodia'
  },
  {
    iso: 'KI',
    latitude: -3.370417,
    longitude: -168.734039,
    emoji: '🇰🇮',
    unicode: 'U+1F1F0 U+1F1EE',
    name: 'Kiribati',
    name_alternate: 'Kiribati'
  },
  {
    iso: 'KM',
    latitude: -11.875001,
    longitude: 43.872219,
    emoji: '🇰🇲',
    unicode: 'U+1F1F0 U+1F1F2',
    name: 'Comoros',
    name_alternate: 'Comoros'
  },
  {
    iso: 'KN',
    latitude: 17.357822,
    longitude: -62.782998,
    emoji: '🇰🇳',
    unicode: 'U+1F1F0 U+1F1F3',
    name: 'Saint Kitts and Nevis',
    name_alternate: 'Saint Kitts and Nevis'
  },
  {
    iso: 'KP',
    latitude: 40.339852,
    longitude: 127.510093,
    emoji: '🇰🇵',
    unicode: 'U+1F1F0 U+1F1F5',
    name: 'North Korea',
    name_alternate: 'North Korea'
  },
  {
    iso: 'KR',
    latitude: 35.907757,
    longitude: 127.766922,
    emoji: '🇰🇷',
    unicode: 'U+1F1F0 U+1F1F7',
    name: 'South Korea',
    name_alternate: 'South Korea'
  },
  {
    iso: 'KW',
    latitude: 29.31166,
    longitude: 47.481766,
    emoji: '🇰🇼',
    unicode: 'U+1F1F0 U+1F1FC',
    name: 'Kuwait',
    name_alternate: 'Kuwait'
  },
  {
    iso: 'KY',
    latitude: 19.513469,
    longitude: -80.566956,
    emoji: '🇰🇾',
    unicode: 'U+1F1F0 U+1F1FE',
    name: 'Cayman Islands',
    name_alternate: 'Cayman Islands'
  },
  {
    iso: 'KZ',
    latitude: 48.019573,
    longitude: 66.923684,
    emoji: '🇰🇿',
    unicode: 'U+1F1F0 U+1F1FF',
    name: 'Kazakhstan',
    name_alternate: 'Kazakhstan'
  },
  {
    iso: 'LA',
    latitude: 19.85627,
    longitude: 102.495496,
    emoji: '🇱🇦',
    unicode: 'U+1F1F1 U+1F1E6',
    name: 'Lao People\'s Democratic Republic',
    name_alternate: 'Laos'
  },
  {
    iso: 'LB',
    latitude: 33.854721,
    longitude: 35.862285,
    emoji: '🇱🇧',
    unicode: 'U+1F1F1 U+1F1E7',
    name: 'Lebanon',
    name_alternate: 'Lebanon'
  },
  {
    iso: 'LC',
    latitude: 13.909444,
    longitude: -60.978893,
    emoji: '🇱🇨',
    unicode: 'U+1F1F1 U+1F1E8',
    name: 'Saint Lucia',
    name_alternate: 'Saint Lucia'
  },
  {
    iso: 'LI',
    latitude: 47.166,
    longitude: 9.555373,
    emoji: '🇱🇮',
    unicode: 'U+1F1F1 U+1F1EE',
    name: 'Liechtenstein',
    name_alternate: 'Liechtenstein'
  },
  {
    iso: 'LK',
    latitude: 7.873054,
    longitude: 80.771797,
    emoji: '🇱🇰',
    unicode: 'U+1F1F1 U+1F1F0',
    name: 'Sri Lanka',
    name_alternate: 'Sri Lanka'
  },
  {
    iso: 'LR',
    latitude: 6.428055,
    longitude: -9.429499,
    emoji: '🇱🇷',
    unicode: 'U+1F1F1 U+1F1F7',
    name: 'Liberia',
    name_alternate: 'Liberia'
  },
  {
    iso: 'LS',
    latitude: -29.609988,
    longitude: 28.233608,
    emoji: '🇱🇸',
    unicode: 'U+1F1F1 U+1F1F8',
    name: 'Lesotho',
    name_alternate: 'Lesotho'
  },
  {
    iso: 'LT',
    latitude: 55.169438,
    longitude: 23.881275,
    emoji: '🇱🇹',
    unicode: 'U+1F1F1 U+1F1F9',
    name: 'Lithuania',
    name_alternate: 'Lithuania'
  },
  {
    iso: 'LU',
    latitude: 49.815273,
    longitude: 6.129583,
    emoji: '🇱🇺',
    unicode: 'U+1F1F1 U+1F1FA',
    name: 'Luxembourg',
    name_alternate: 'Luxembourg'
  },
  {
    iso: 'LV',
    latitude: 56.879635,
    longitude: 24.603189,
    emoji: '🇱🇻',
    unicode: 'U+1F1F1 U+1F1FB',
    name: 'Latvia',
    name_alternate: 'Latvia'
  },
  {
    iso: 'LY',
    latitude: 26.3351,
    longitude: 17.228331,
    emoji: '🇱🇾',
    unicode: 'U+1F1F1 U+1F1FE',
    name: 'Libya',
    name_alternate: 'Libya'
  },
  {
    iso: 'MA',
    latitude: 31.791702,
    longitude: -7.09262,
    emoji: '🇲🇦',
    unicode: 'U+1F1F2 U+1F1E6',
    name: 'Morocco',
    name_alternate: 'Morocco'
  },
  {
    iso: 'MC',
    latitude: 43.750298,
    longitude: 7.412841,
    emoji: '🇲🇨',
    unicode: 'U+1F1F2 U+1F1E8',
    name: 'Monaco',
    name_alternate: 'Monaco'
  },
  {
    iso: 'MD',
    latitude: 47.411631,
    longitude: 28.369885,
    emoji: '🇲🇩',
    unicode: 'U+1F1F2 U+1F1E9',
    name: 'Moldova',
    name_alternate: 'Moldova'
  },
  {
    iso: 'ME',
    latitude: 42.708678,
    longitude: 19.37439,
    emoji: '🇲🇪',
    unicode: 'U+1F1F2 U+1F1EA',
    name: 'Montenegro',
    name_alternate: 'Montenegro'
  },
  {
    iso: 'MF',
    emoji: '🇲🇫',
    unicode: 'U+1F1F2 U+1F1EB',
    name: 'Saint Martin (French Part)'
  },
  {
    iso: 'MG',
    latitude: -18.766947,
    longitude: 46.869107,
    emoji: '🇲🇬',
    unicode: 'U+1F1F2 U+1F1EC',
    name: 'Madagascar',
    name_alternate: 'Madagascar'
  },
  {
    iso: 'MH',
    latitude: 7.131474,
    longitude: 171.184478,
    emoji: '🇲🇭',
    unicode: 'U+1F1F2 U+1F1ED',
    name: 'Marshall Islands',
    name_alternate: 'Marshall Islands'
  },
  {
    iso: 'MK',
    latitude: 41.608635,
    longitude: 21.745275,
    emoji: '🇲🇰',
    unicode: 'U+1F1F2 U+1F1F0',
    name: 'Macedonia',
    name_alternate: 'Macedonia [FYROM]'
  },
  {
    iso: 'ML',
    latitude: 17.570692,
    longitude: -3.996166,
    emoji: '🇲🇱',
    unicode: 'U+1F1F2 U+1F1F1',
    name: 'Mali',
    name_alternate: 'Mali'
  },
  {
    iso: 'MM',
    latitude: 21.913965,
    longitude: 95.956223,
    emoji: '🇲🇲',
    unicode: 'U+1F1F2 U+1F1F2',
    name: 'Myanmar',
    name_alternate: 'Myanmar [Burma]'
  },
  {
    iso: 'MN',
    latitude: 46.862496,
    longitude: 103.846656,
    emoji: '🇲🇳',
    unicode: 'U+1F1F2 U+1F1F3',
    name: 'Mongolia',
    name_alternate: 'Mongolia'
  },
  {
    iso: 'MO',
    latitude: 22.198745,
    longitude: 113.543873,
    emoji: '🇲🇴',
    unicode: 'U+1F1F2 U+1F1F4',
    name: 'Macao',
    name_alternate: 'Macau'
  },
  {
    iso: 'MP',
    latitude: 17.33083,
    longitude: 145.38469,
    emoji: '🇲🇵',
    unicode: 'U+1F1F2 U+1F1F5',
    name: 'Northern Mariana Islands',
    name_alternate: 'Northern Mariana Islands'
  },
  {
    iso: 'MQ',
    latitude: 14.641528,
    longitude: -61.024174,
    emoji: '🇲🇶',
    unicode: 'U+1F1F2 U+1F1F6',
    name: 'Martinique',
    name_alternate: 'Martinique'
  },
  {
    iso: 'MR',
    latitude: 21.00789,
    longitude: -10.940835,
    emoji: '🇲🇷',
    unicode: 'U+1F1F2 U+1F1F7',
    name: 'Mauritania',
    name_alternate: 'Mauritania'
  },
  {
    iso: 'MS',
    latitude: 16.742498,
    longitude: -62.187366,
    emoji: '🇲🇸',
    unicode: 'U+1F1F2 U+1F1F8',
    name: 'Montserrat',
    name_alternate: 'Montserrat'
  },
  {
    iso: 'MT',
    latitude: 35.937496,
    longitude: 14.375416,
    emoji: '🇲🇹',
    unicode: 'U+1F1F2 U+1F1F9',
    name: 'Malta',
    name_alternate: 'Malta'
  },
  {
    iso: 'MU',
    latitude: -20.348404,
    longitude: 57.552152,
    emoji: '🇲🇺',
    unicode: 'U+1F1F2 U+1F1FA',
    name: 'Mauritius',
    name_alternate: 'Mauritius'
  },
  {
    iso: 'MV',
    latitude: 3.202778,
    longitude: 73.22068,
    emoji: '🇲🇻',
    unicode: 'U+1F1F2 U+1F1FB',
    name: 'Maldives',
    name_alternate: 'Maldives'
  },
  {
    iso: 'MW',
    latitude: -13.254308,
    longitude: 34.301525,
    emoji: '🇲🇼',
    unicode: 'U+1F1F2 U+1F1FC',
    name: 'Malawi',
    name_alternate: 'Malawi'
  },
  {
    iso: 'MX',
    latitude: 23.634501,
    longitude: -102.552784,
    emoji: '🇲🇽',
    unicode: 'U+1F1F2 U+1F1FD',
    name: 'Mexico',
    name_alternate: 'Mexico'
  },
  {
    iso: 'MY',
    latitude: 4.210484,
    longitude: 101.975766,
    emoji: '🇲🇾',
    unicode: 'U+1F1F2 U+1F1FE',
    name: 'Malaysia',
    name_alternate: 'Malaysia'
  },
  {
    iso: 'MZ',
    latitude: -18.665695,
    longitude: 35.529562,
    emoji: '🇲🇿',
    unicode: 'U+1F1F2 U+1F1FF',
    name: 'Mozambique',
    name_alternate: 'Mozambique'
  },
  {
    iso: 'NA',
    latitude: -22.95764,
    longitude: 18.49041,
    emoji: '🇳🇦',
    unicode: 'U+1F1F3 U+1F1E6',
    name: 'Namibia',
    name_alternate: 'Namibia'
  },
  {
    iso: 'NC',
    latitude: -20.904305,
    longitude: 165.618042,
    emoji: '🇳🇨',
    unicode: 'U+1F1F3 U+1F1E8',
    name: 'New Caledonia',
    name_alternate: 'New Caledonia'
  },
  {
    iso: 'NE',
    latitude: 17.607789,
    longitude: 8.081666,
    emoji: '🇳🇪',
    unicode: 'U+1F1F3 U+1F1EA',
    name: 'Niger',
    name_alternate: 'Niger'
  },
  {
    iso: 'NF',
    latitude: -29.040835,
    longitude: 167.954712,
    emoji: '🇳🇫',
    unicode: 'U+1F1F3 U+1F1EB',
    name: 'Norfolk Island',
    name_alternate: 'Norfolk Island'
  },
  {
    iso: 'NG',
    latitude: 9.081999,
    longitude: 8.675277,
    emoji: '🇳🇬',
    unicode: 'U+1F1F3 U+1F1EC',
    name: 'Nigeria',
    name_alternate: 'Nigeria'
  },
  {
    iso: 'NI',
    latitude: 12.865416,
    longitude: -85.207229,
    emoji: '🇳🇮',
    unicode: 'U+1F1F3 U+1F1EE',
    name: 'Nicaragua',
    name_alternate: 'Nicaragua'
  },
  {
    iso: 'NL',
    latitude: 52.132633,
    longitude: 5.291266,
    emoji: '🇳🇱',
    unicode: 'U+1F1F3 U+1F1F1',
    name: 'Netherlands',
    name_alternate: 'Netherlands'
  },
  {
    iso: 'NO',
    latitude: 60.472024,
    longitude: 8.468946,
    emoji: '🇳🇴',
    unicode: 'U+1F1F3 U+1F1F4',
    name: 'Norway',
    name_alternate: 'Norway'
  },
  {
    iso: 'NP',
    latitude: 28.394857,
    longitude: 84.124008,
    emoji: '🇳🇵',
    unicode: 'U+1F1F3 U+1F1F5',
    name: 'Nepal',
    name_alternate: 'Nepal'
  },
  {
    iso: 'NR',
    latitude: -0.522778,
    longitude: 166.931503,
    emoji: '🇳🇷',
    unicode: 'U+1F1F3 U+1F1F7',
    name: 'Nauru',
    name_alternate: 'Nauru'
  },
  {
    iso: 'NU',
    latitude: -19.054445,
    longitude: -169.867233,
    emoji: '🇳🇺',
    unicode: 'U+1F1F3 U+1F1FA',
    name: 'Niue',
    name_alternate: 'Niue'
  },
  {
    iso: 'NZ',
    latitude: -40.900557,
    longitude: 174.885971,
    emoji: '🇳🇿',
    unicode: 'U+1F1F3 U+1F1FF',
    name: 'New Zealand',
    name_alternate: 'New Zealand'
  },
  {
    iso: 'OM',
    latitude: 21.512583,
    longitude: 55.923255,
    emoji: '🇴🇲',
    unicode: 'U+1F1F4 U+1F1F2',
    name: 'Oman',
    name_alternate: 'Oman'
  },
  {
    iso: 'PA',
    latitude: 8.537981,
    longitude: -80.782127,
    emoji: '🇵🇦',
    unicode: 'U+1F1F5 U+1F1E6',
    name: 'Panama',
    name_alternate: 'Panama'
  },
  {
    iso: 'PE',
    latitude: -9.189967,
    longitude: -75.015152,
    emoji: '🇵🇪',
    unicode: 'U+1F1F5 U+1F1EA',
    name: 'Peru',
    name_alternate: 'Peru'
  },
  {
    iso: 'PF',
    latitude: -17.679742,
    longitude: -149.406843,
    emoji: '🇵🇫',
    unicode: 'U+1F1F5 U+1F1EB',
    name: 'French Polynesia',
    name_alternate: 'French Polynesia'
  },
  {
    iso: 'PG',
    latitude: -6.314993,
    longitude: 143.95555,
    emoji: '🇵🇬',
    unicode: 'U+1F1F5 U+1F1EC',
    name: 'Papua New Guinea',
    name_alternate: 'Papua New Guinea'
  },
  {
    iso: 'PH',
    latitude: 12.879721,
    longitude: 121.774017,
    emoji: '🇵🇭',
    unicode: 'U+1F1F5 U+1F1ED',
    name: 'Philippines',
    name_alternate: 'Philippines'
  },
  {
    iso: 'PK',
    latitude: 30.375321,
    longitude: 69.345116,
    emoji: '🇵🇰',
    unicode: 'U+1F1F5 U+1F1F0',
    name: 'Pakistan',
    name_alternate: 'Pakistan'
  },
  {
    iso: 'PL',
    latitude: 51.919438,
    longitude: 19.145136,
    emoji: '🇵🇱',
    unicode: 'U+1F1F5 U+1F1F1',
    name: 'Poland',
    name_alternate: 'Poland'
  },
  {
    iso: 'PM',
    latitude: 46.941936,
    longitude: -56.27111,
    emoji: '🇵🇲',
    unicode: 'U+1F1F5 U+1F1F2',
    name: 'Saint Pierre and Miquelon',
    name_alternate: 'Saint Pierre and Miquelon'
  },
  {
    iso: 'PN',
    latitude: -24.703615,
    longitude: -127.439308,
    emoji: '🇵🇳',
    unicode: 'U+1F1F5 U+1F1F3',
    name: 'Pitcairn',
    name_alternate: 'Pitcairn Islands'
  },
  {
    iso: 'PR',
    latitude: 18.220833,
    longitude: -66.590149,
    emoji: '🇵🇷',
    unicode: 'U+1F1F5 U+1F1F7',
    name: 'Puerto Rico',
    name_alternate: 'Puerto Rico'
  },
  {
    iso: 'PS',
    latitude: 31.952162,
    longitude: 35.233154,
    emoji: '🇵🇸',
    unicode: 'U+1F1F5 U+1F1F8',
    name: 'Palestinian Territory',
    name_alternate: 'Palestinian Territories'
  },
  {
    iso: 'PT',
    latitude: 39.399872,
    longitude: -8.224454,
    emoji: '🇵🇹',
    unicode: 'U+1F1F5 U+1F1F9',
    name: 'Portugal',
    name_alternate: 'Portugal'
  },
  {
    iso: 'PW',
    latitude: 7.51498,
    longitude: 134.58252,
    emoji: '🇵🇼',
    unicode: 'U+1F1F5 U+1F1FC',
    name: 'Palau',
    name_alternate: 'Palau'
  },
  {
    iso: 'PY',
    latitude: -23.442503,
    longitude: -58.443832,
    emoji: '🇵🇾',
    unicode: 'U+1F1F5 U+1F1FE',
    name: 'Paraguay',
    name_alternate: 'Paraguay'
  },
  {
    iso: 'QA',
    latitude: 25.354826,
    longitude: 51.183884,
    emoji: '🇶🇦',
    unicode: 'U+1F1F6 U+1F1E6',
    name: 'Qatar',
    name_alternate: 'Qatar'
  },
  {
    iso: 'RE',
    latitude: -21.115141,
    longitude: 55.536384,
    emoji: '🇷🇪',
    unicode: 'U+1F1F7 U+1F1EA',
    name: 'Réunion',
    name_alternate: 'Réunion'
  },
  {
    iso: 'RO',
    latitude: 45.943161,
    longitude: 24.96676,
    emoji: '🇷🇴',
    unicode: 'U+1F1F7 U+1F1F4',
    name: 'Romania',
    name_alternate: 'Romania'
  },
  {
    iso: 'RS',
    latitude: 44.016521,
    longitude: 21.005859,
    emoji: '🇷🇸',
    unicode: 'U+1F1F7 U+1F1F8',
    name: 'Serbia',
    name_alternate: 'Serbia'
  },
  {
    iso: 'RU',
    latitude: 61.52401,
    longitude: 105.318756,
    emoji: '🇷🇺',
    unicode: 'U+1F1F7 U+1F1FA',
    name: 'Russia',
    name_alternate: 'Russia'
  },
  {
    iso: 'RW',
    latitude: -1.940278,
    longitude: 29.873888,
    emoji: '🇷🇼',
    unicode: 'U+1F1F7 U+1F1FC',
    name: 'Rwanda',
    name_alternate: 'Rwanda'
  },
  {
    iso: 'SA',
    latitude: 23.885942,
    longitude: 45.079162,
    emoji: '🇸🇦',
    unicode: 'U+1F1F8 U+1F1E6',
    name: 'Saudi Arabia',
    name_alternate: 'Saudi Arabia'
  },
  {
    iso: 'SB',
    latitude: -9.64571,
    longitude: 160.156194,
    emoji: '🇸🇧',
    unicode: 'U+1F1F8 U+1F1E7',
    name: 'Solomon Islands',
    name_alternate: 'Solomon Islands'
  },
  {
    iso: 'SC',
    latitude: -4.679574,
    longitude: 55.491977,
    emoji: '🇸🇨',
    unicode: 'U+1F1F8 U+1F1E8',
    name: 'Seychelles',
    name_alternate: 'Seychelles'
  },
  {
    iso: 'SD',
    latitude: 12.862807,
    longitude: 30.217636,
    emoji: '🇸🇩',
    unicode: 'U+1F1F8 U+1F1E9',
    name: 'Sudan',
    name_alternate: 'Sudan'
  },
  {
    iso: 'SE',
    latitude: 60.128161,
    longitude: 18.643501,
    emoji: '🇸🇪',
    unicode: 'U+1F1F8 U+1F1EA',
    name: 'Sweden',
    name_alternate: 'Sweden'
  },
  {
    iso: 'SG',
    latitude: 1.352083,
    longitude: 103.819836,
    emoji: '🇸🇬',
    unicode: 'U+1F1F8 U+1F1EC',
    name: 'Singapore',
    name_alternate: 'Singapore'
  },
  {
    iso: 'SH',
    latitude: -24.143474,
    longitude: -10.030696,
    emoji: '🇸🇭',
    unicode: 'U+1F1F8 U+1F1ED',
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    name_alternate: 'Saint Helena'
  },
  {
    iso: 'SI',
    latitude: 46.151241,
    longitude: 14.995463,
    emoji: '🇸🇮',
    unicode: 'U+1F1F8 U+1F1EE',
    name: 'Slovenia',
    name_alternate: 'Slovenia'
  },
  {
    iso: 'SJ',
    latitude: 77.553604,
    longitude: 23.670272,
    emoji: '🇸🇯',
    unicode: 'U+1F1F8 U+1F1EF',
    name: 'Svalbard and Jan Mayen',
    name_alternate: 'Svalbard and Jan Mayen'
  },
  {
    iso: 'SK',
    latitude: 48.669026,
    longitude: 19.699024,
    emoji: '🇸🇰',
    unicode: 'U+1F1F8 U+1F1F0',
    name: 'Slovakia',
    name_alternate: 'Slovakia'
  },
  {
    iso: 'SL',
    latitude: 8.460555,
    longitude: -11.779889,
    emoji: '🇸🇱',
    unicode: 'U+1F1F8 U+1F1F1',
    name: 'Sierra Leone',
    name_alternate: 'Sierra Leone'
  },
  {
    iso: 'SM',
    latitude: 43.94236,
    longitude: 12.457777,
    emoji: '🇸🇲',
    unicode: 'U+1F1F8 U+1F1F2',
    name: 'San Marino',
    name_alternate: 'San Marino'
  },
  {
    iso: 'SN',
    latitude: 14.497401,
    longitude: -14.452362,
    emoji: '🇸🇳',
    unicode: 'U+1F1F8 U+1F1F3',
    name: 'Senegal',
    name_alternate: 'Senegal'
  },
  {
    iso: 'SO',
    latitude: 5.152149,
    longitude: 46.199616,
    emoji: '🇸🇴',
    unicode: 'U+1F1F8 U+1F1F4',
    name: 'Somalia',
    name_alternate: 'Somalia'
  },
  {
    iso: 'SR',
    latitude: 3.919305,
    longitude: -56.027783,
    emoji: '🇸🇷',
    unicode: 'U+1F1F8 U+1F1F7',
    name: 'Suriname',
    name_alternate: 'Suriname'
  },
  {
    iso: 'SS',
    emoji: '🇸🇸',
    unicode: 'U+1F1F8 U+1F1F8',
    name: 'South Sudan'
  },
  {
    iso: 'ST',
    latitude: 0.18636,
    longitude: 6.613081,
    emoji: '🇸🇹',
    unicode: 'U+1F1F8 U+1F1F9',
    name: 'Sao Tome and Principe',
    name_alternate: 'São Tomé and Príncipe'
  },
  {
    iso: 'SV',
    latitude: 13.794185,
    longitude: -88.89653,
    emoji: '🇸🇻',
    unicode: 'U+1F1F8 U+1F1FB',
    name: 'El Salvador',
    name_alternate: 'El Salvador'
  },
  {
    iso: 'SX',
    emoji: '🇸🇽',
    unicode: 'U+1F1F8 U+1F1FD',
    name: 'Sint Maarten (Dutch Part)'
  },
  {
    iso: 'SY',
    latitude: 34.802075,
    longitude: 38.996815,
    emoji: '🇸🇾',
    unicode: 'U+1F1F8 U+1F1FE',
    name: 'Syrian Arab Republic',
    name_alternate: 'Syria'
  },
  {
    iso: 'SZ',
    latitude: -26.522503,
    longitude: 31.465866,
    emoji: '🇸🇿',
    unicode: 'U+1F1F8 U+1F1FF',
    name: 'Swaziland',
    name_alternate: 'Swaziland'
  },
  {
    iso: 'TC',
    latitude: 21.694025,
    longitude: -71.797928,
    emoji: '🇹🇨',
    unicode: 'U+1F1F9 U+1F1E8',
    name: 'Turks and Caicos Islands',
    name_alternate: 'Turks and Caicos Islands'
  },
  {
    iso: 'TD',
    latitude: 15.454166,
    longitude: 18.732207,
    emoji: '🇹🇩',
    unicode: 'U+1F1F9 U+1F1E9',
    name: 'Chad',
    name_alternate: 'Chad'
  },
  {
    iso: 'TF',
    latitude: -49.280366,
    longitude: 69.348557,
    emoji: '🇹🇫',
    unicode: 'U+1F1F9 U+1F1EB',
    name: 'French Southern Territories',
    name_alternate: 'French Southern Territories'
  },
  {
    iso: 'TG',
    latitude: 8.619543,
    longitude: 0.824782,
    emoji: '🇹🇬',
    unicode: 'U+1F1F9 U+1F1EC',
    name: 'Togo',
    name_alternate: 'Togo'
  },
  {
    iso: 'TH',
    latitude: 15.870032,
    longitude: 100.992541,
    emoji: '🇹🇭',
    unicode: 'U+1F1F9 U+1F1ED',
    name: 'Thailand',
    name_alternate: 'Thailand'
  },
  {
    iso: 'TJ',
    latitude: 38.861034,
    longitude: 71.276093,
    emoji: '🇹🇯',
    unicode: 'U+1F1F9 U+1F1EF',
    name: 'Tajikistan',
    name_alternate: 'Tajikistan'
  },
  {
    iso: 'TK',
    latitude: -8.967363,
    longitude: -171.855881,
    emoji: '🇹🇰',
    unicode: 'U+1F1F9 U+1F1F0',
    name: 'Tokelau',
    name_alternate: 'Tokelau'
  },
  {
    iso: 'TL',
    latitude: -8.874217,
    longitude: 125.727539,
    emoji: '🇹🇱',
    unicode: 'U+1F1F9 U+1F1F1',
    name: 'Timor-Leste',
    name_alternate: 'Timor-Leste'
  },
  {
    iso: 'TM',
    latitude: 38.969719,
    longitude: 59.556278,
    emoji: '🇹🇲',
    unicode: 'U+1F1F9 U+1F1F2',
    name: 'Turkmenistan',
    name_alternate: 'Turkmenistan'
  },
  {
    iso: 'TN',
    latitude: 33.886917,
    longitude: 9.537499,
    emoji: '🇹🇳',
    unicode: 'U+1F1F9 U+1F1F3',
    name: 'Tunisia',
    name_alternate: 'Tunisia'
  },
  {
    iso: 'TO',
    latitude: -21.178986,
    longitude: -175.198242,
    emoji: '🇹🇴',
    unicode: 'U+1F1F9 U+1F1F4',
    name: 'Tonga',
    name_alternate: 'Tonga'
  },
  {
    iso: 'TR',
    latitude: 38.963745,
    longitude: 35.243322,
    emoji: '🇹🇷',
    unicode: 'U+1F1F9 U+1F1F7',
    name: 'Turkey',
    name_alternate: 'Turkey'
  },
  {
    iso: 'TT',
    latitude: 10.691803,
    longitude: -61.222503,
    emoji: '🇹🇹',
    unicode: 'U+1F1F9 U+1F1F9',
    name: 'Trinidad and Tobago',
    name_alternate: 'Trinidad and Tobago'
  },
  {
    iso: 'TV',
    latitude: -7.109535,
    longitude: 177.64933,
    emoji: '🇹🇻',
    unicode: 'U+1F1F9 U+1F1FB',
    name: 'Tuvalu',
    name_alternate: 'Tuvalu'
  },
  {
    iso: 'TW',
    latitude: 23.69781,
    longitude: 120.960515,
    emoji: '🇹🇼',
    unicode: 'U+1F1F9 U+1F1FC',
    name: 'Taiwan',
    name_alternate: 'Taiwan'
  },
  {
    iso: 'TZ',
    latitude: -6.369028,
    longitude: 34.888822,
    emoji: '🇹🇿',
    unicode: 'U+1F1F9 U+1F1FF',
    name: 'Tanzania',
    name_alternate: 'Tanzania'
  },
  {
    iso: 'UA',
    latitude: 48.379433,
    longitude: 31.16558,
    emoji: '🇺🇦',
    unicode: 'U+1F1FA U+1F1E6',
    name: 'Ukraine',
    name_alternate: 'Ukraine'
  },
  {
    iso: 'UG',
    latitude: 1.373333,
    longitude: 32.290275,
    emoji: '🇺🇬',
    unicode: 'U+1F1FA U+1F1EC',
    name: 'Uganda',
    name_alternate: 'Uganda'
  },
  {
    iso: 'UM',
    emoji: '🇺🇲',
    unicode: 'U+1F1FA U+1F1F2',
    name: 'United States Minor Outlying Islands',
    name_alternate: 'U.S. Minor Outlying Islands'
  },
  {
    iso: 'US',
    latitude: 37.09024,
    longitude: -95.712891,
    emoji: '🇺🇸',
    unicode: 'U+1F1FA U+1F1F8',
    name: 'United States',
    name_alternate: 'United States'
  },
  {
    iso: 'UY',
    latitude: -32.522779,
    longitude: -55.765835,
    emoji: '🇺🇾',
    unicode: 'U+1F1FA U+1F1FE',
    name: 'Uruguay',
    name_alternate: 'Uruguay'
  },
  {
    iso: 'UZ',
    latitude: 41.377491,
    longitude: 64.585262,
    emoji: '🇺🇿',
    unicode: 'U+1F1FA U+1F1FF',
    name: 'Uzbekistan',
    name_alternate: 'Uzbekistan'
  },
  {
    iso: 'VA',
    latitude: 41.902916,
    longitude: 12.453389,
    emoji: '🇻🇦',
    unicode: 'U+1F1FB U+1F1E6',
    name: 'Vatican City',
    name_alternate: 'Vatican City'
  },
  {
    iso: 'VC',
    latitude: 12.984305,
    longitude: -61.287228,
    emoji: '🇻🇨',
    unicode: 'U+1F1FB U+1F1E8',
    name: 'Saint Vincent and The Grenadines',
    name_alternate: 'Saint Vincent and the Grenadines'
  },
  {
    iso: 'VE',
    latitude: 6.42375,
    longitude: -66.58973,
    emoji: '🇻🇪',
    unicode: 'U+1F1FB U+1F1EA',
    name: 'Venezuela',
    name_alternate: 'Venezuela'
  },
  {
    iso: 'VG',
    latitude: 18.420695,
    longitude: -64.639968,
    emoji: '🇻🇬',
    unicode: 'U+1F1FB U+1F1EC',
    name: 'Virgin Islands, British',
    name_alternate: 'British Virgin Islands'
  },
  {
    iso: 'VI',
    latitude: 18.335765,
    longitude: -64.896335,
    emoji: '🇻🇮',
    unicode: 'U+1F1FB U+1F1EE',
    name: 'Virgin Islands, U.S.',
    name_alternate: 'U.S. Virgin Islands'
  },
  {
    iso: 'VN',
    latitude: 14.058324,
    longitude: 108.277199,
    emoji: '🇻🇳',
    unicode: 'U+1F1FB U+1F1F3',
    name: 'Viet Nam',
    name_alternate: 'Vietnam'
  },
  {
    iso: 'VU',
    latitude: -15.376706,
    longitude: 166.959158,
    emoji: '🇻🇺',
    unicode: 'U+1F1FB U+1F1FA',
    name: 'Vanuatu',
    name_alternate: 'Vanuatu'
  },
  {
    iso: 'WF',
    latitude: -13.768752,
    longitude: -177.156097,
    emoji: '🇼🇫',
    unicode: 'U+1F1FC U+1F1EB',
    name: 'Wallis and Futuna',
    name_alternate: 'Wallis and Futuna'
  },
  {
    iso: 'WS',
    latitude: -13.759029,
    longitude: -172.104629,
    emoji: '🇼🇸',
    unicode: 'U+1F1FC U+1F1F8',
    name: 'Samoa',
    name_alternate: 'Samoa'
  },
  {
    iso: 'XK',
    latitude: 42.602636,
    longitude: 20.902977,
    name: 'Kosovo',
    name_alternate: 'Kosovo'
  },
  {
    iso: 'YE',
    latitude: 15.552727,
    longitude: 48.516388,
    emoji: '🇾🇪',
    unicode: 'U+1F1FE U+1F1EA',
    name: 'Yemen',
    name_alternate: 'Yemen'
  },
  {
    iso: 'YT',
    latitude: -12.8275,
    longitude: 45.166244,
    emoji: '🇾🇹',
    unicode: 'U+1F1FE U+1F1F9',
    name: 'Mayotte',
    name_alternate: 'Mayotte'
  },
  {
    iso: 'ZA',
    latitude: -30.559482,
    longitude: 22.937506,
    emoji: '🇿🇦',
    unicode: 'U+1F1FF U+1F1E6',
    name: 'South Africa',
    name_alternate: 'South Africa'
  },
  {
    iso: 'ZM',
    latitude: -13.133897,
    longitude: 27.849332,
    emoji: '🇿🇲',
    unicode: 'U+1F1FF U+1F1F2',
    name: 'Zambia',
    name_alternate: 'Zambia'
  },
  {
    iso: 'ZW',
    latitude: -19.015438,
    longitude: 29.154857,
    emoji: '🇿🇼',
    unicode: 'U+1F1FF U+1F1FC',
    name: 'Zimbabwe',
    name_alternate: 'Zimbabwe'
  }
];



@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor() { }

  get(iso: string): Country {
    return countries.find(c => c.iso.toLowerCase() === iso.toLowerCase());
  }
}
