import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Subscription } from 'rxjs';
import firebase from 'firebase/app';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import md5 from 'md5';
import { ActivatedRoute } from '@angular/router';

import { build } from '../../environments/build';
import { CommonService } from '../common.service';
import { Country, CountryService } from '../country.service';
import { Breadcrumb, User, UserService } from '../user.service';
import { CamerarollService, Polaroid } from '../cameraroll.service';

export type GestureHandlingOptions = 'cooperative' | 'greedy' | 'none' | 'auto';

@Component({
  selector: 'app-home-bottom-sheet-help',
  templateUrl: 'home-bottom-sheet-help.html',
})
export class BottomSheetHelpComponent {
  build: any;

  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetHelpComponent>) {
    this.build = build;
  }

  dismiss(): void {
    this.bottomSheetRef.dismiss();
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  buildDate(): string {
    const buildDate = new Date(this.build.time);
    return `${buildDate.toLocaleString()}`;
  }
}

export interface ShareConfig {
  latitude: number;
  longitude: number;
  zoom: number;
  heading: number;
  uid: string;
  shareInfo?: any;
}

@Component({
  selector: 'app-home-share-dialog',
  templateUrl: './home-share-dialog.html',
})
export class HomeShareDialogComponent {
  @ViewChild('previewMap', { static: false }) mapElement: any;
  map: google.maps.Map;

  shareId: string;
  headerImage: string;

  opacity = 1.0;

  constructor(
    private cameraroll: CamerarollService,
    private common: CommonService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<HomeShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: ShareConfig) {
    this.fadeIn();
    setTimeout(() => {
      const mapProperties = {
        center: { lat: config.latitude, lng: config.longitude },
        zoom: config.zoom - 1,
        heading: config.heading,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy' as GestureHandlingOptions,
        minZoom: 2,
        disableDefaultUI: true,
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        restriction: {
          latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180
          },
          strictBounds: true,
        },
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      const shareId = md5(`${this.config.uid} ${this.config.latitude} ${this.config.longitude} ${this.config.zoom}`);
      const latitude = this.config.latitude;
      const longitude = this.config.longitude;
      const zoom = this.config.zoom;
      const heading = this.config.heading;
      const uid = this.config.uid;
      google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        if (this.config.shareInfo) {
          this.shareId = this.config.shareInfo.id;
          this.opacity = 0;
        } else {
          html2canvas(document.getElementById('preview'), {
            useCORS: true,
          }).then(canvas => {
            canvas.toBlob(async blob => {
              await this.storage.ref(`shareImage/${shareId}.jpg`).put(blob);
              await this.storage.ref(`shareImage/${shareId}.jpg`).getDownloadURL().toPromise().then(url => {
                this.headerImage = url;
              });
              this.cameraroll.add({
                image: this.headerImage,
                latitude,
                longitude,
                zoom,
                heading,
                id: shareId,
                uid,
              });
              await this.afs.collection('share').doc(shareId).set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                image: this.headerImage,
                latitude,
                longitude,
                zoom,
                heading,
                id: shareId,
                uid,
              });
              this.shareId = shareId;
              this.opacity = 0;
            }, 'image/jpeg');
          });
        }
      });
    }, 100);
  }

  fadeIn(): void {
    setTimeout(() => {
      this.opacity -= 0.02;
      if (this.opacity > 0) {
        this.fadeIn();
      }
    }, 200);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  copyToClipboard(): void {
    this.common.copyToClipboard(`${window.location.protocol}//${window.location.host}/share/${this.shareId}`);
    this.dialogRef.close();
  }

  share(channel: string): void {
    this.common.share(channel, `${window.location.protocol}//${window.location.host}/share/${this.shareId}`);
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: false }) mapElement: any;
  map: google.maps.Map;
  labels = false;

  user: firebase.User | null = null;
  userRef: Subscription | undefined;

  intro = true;

  setIntervalId;
  latitude;
  longitude;
  zoom;
  heading;
  shareId;
  shareInfo;
  shareIndex;
  breadcrumbs: Breadcrumb[] = [];
  heatmap;

  userCountry: Country;
  isMobile;

  player;
  playerApiScript;
  music = ['5qap5aO4i9A', 'DWcJFNfaw9c', '5yx6BWlEVcY', '7NOSDKb0HlU'];
  musicIndex = 0;

  constructor(
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private countryService: CountryService,
    public userService: UserService,
    public cameraroll: CamerarollService,
    private common: CommonService,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.detectMobile();
    this.initYouTube();
    this.route.queryParams.subscribe(async params => {
      if (params.lat && params.lng && params.z) {
        this.intro = false;
        this.latitude = Number(params.lat);
        this.longitude = Number(params.lng);
        this.zoom = Number(params.z);
        this.heading = Number(params.h);
        this.shareId = params.id;
        this.intro = true;
        window.history.replaceState({}, '', '/');
      }
      if (!this.map) {
        this.initMap();
      }
    });
    if (this.userRef) {
      this.userRef.unsubscribe();
    }
    this.userRef = this.afAuth.user.subscribe(async user => {
      this.user = user;
      if (this.setIntervalId) {
        clearInterval(this.setIntervalId);
        this.setIntervalId = undefined;
      }
      await this.afs.collection('users').doc(this.user.uid).get().toPromise().then(doc => {
        const u = doc.data() as User;
        if (doc.exists && u.breadcrumbs?.length) {
          this.breadcrumbs = u.breadcrumbs;
        }
      });
      if (this.shareId) {
        await this.afs.collection('share').doc(this.shareId).get().toPromise().then(doc => {
          if (doc.exists) {
            this.shareInfo = doc.data() as any;
            if (!this.shareInfo.image?.length) {
              this.shareId = undefined;
            }
          }
        }).catch(() => {
          this.intro = false;
        });
      }
      this.setIntervalId = setInterval(() => {
        const center = this.map.getCenter();
        const currentTime = new Date();
        const epochSeconds = Math.round(currentTime.getTime() / 1000);
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const latitude = center.lat();
        const longitude = center.lng();
        const zoom = this.map.getZoom();
        const isMobile = this.detectMobile();
        if (latitude !== this.latitude || longitude !== this.longitude || zoom !== this.zoom) {
          this.breadcrumbs.push({
            latitude: +center.lat().toPrecision(4),
            longitude: +center.lng().toPrecision(4),
            zoom
          });
          this.breadcrumbs = this.breadcrumbs.slice(-20);
          if (epochSeconds % 3 === 0) {
            this.afs.collection('users').doc(this.user.uid).set({
              updated: firebase.firestore.FieldValue.serverTimestamp(),
              latitude,
              longitude,
              zoom,
              language: navigator.language.split('-')[0],
              locale: navigator.language.split('-')[1],
              hours,
              minutes,
              uid: this.user.uid,
              isMobile,
              breadcrumbs: this.breadcrumbs,
            });
            this.latitude = latitude;
            this.longitude = longitude;
            this.zoom = zoom;
          }
        }
      }, 1000);
    });
  }

  ngOnDestroy(): void {
    if (this.setIntervalId) {
      clearInterval(this.setIntervalId);
      this.setIntervalId = undefined;
    }
  }

  initMap(): void {
    setTimeout(() => {
      const mapProperties = {
        center: { lat: 0, lng: 0 },
        zoom: 3,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: true,
        gestureHandling: 'greedy' as GestureHandlingOptions,
        minZoom: 3,
        restriction: {
          latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180
          },
          strictBounds: true,
        },
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: this.userService.heatmap
      });
      this.heatmap.setMap(this.map);
      google.maps.event.addListener(this.map, 'zoom_changed', () => {
        if (this.heatmap) {
          if (this.map.getZoom() > 10) {
            this.heatmap.setMap(null);
          } else {
            this.heatmap.setMap(this.map);
          }
        }
      });
      if (!this.userCountry) {
        this.userCountry = this.countryService.get(navigator.language.split('-')[1]);
        if (!(this.latitude && this.longitude && this.zoom)) {
          this.latitude = this.userCountry.latitude ?? 0;
          this.longitude = this.userCountry.longitude ?? 0;
          this.zoom = 3;
        }
        this.map.setCenter({
          lat: this.latitude,
          lng: this.longitude
        });
        this.map.setZoom(this.zoom);
        google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
          this.map.setZoom(this.zoom);
          this.map.setHeading(this.heading ?? 0);
        });
      }
    }, 100);
  }

  initYouTube(): void {
    if (this.player) {
      this.player.cueVideoById(this.music[this.musicIndex], 0);
    } else {
      if (!(window as any).YT) {
        (window as any).onYouTubeIframeAPIReady = () => {
          this.player = new (window as any).YT.Player('ytPlayer', {
            height: '140px',
            width: '250px',
            videoId: this.music[this.musicIndex],
            playerVars: { autoplay: 0, rel: 0, controls: 0, playsinline: 1 },
            events: {
              onReady: (event) => {
              },
              onStateChange: (event) => {
              }
            }
          });
        };

        const doc = (window as any).document;
        this.playerApiScript = doc.createElement('script');
        this.playerApiScript.type = 'text/javascript';
        this.playerApiScript.src = 'https://www.youtube.com/iframe_api';
        doc.body.appendChild(this.playerApiScript);
      } else {
        this.player = new (window as any).YT.Player('ytPlayer', {
          height: '140px',
          width: '250px',
          videoId: this.music[this.musicIndex],
          playerVars: { autoplay: 0, rel: 0, controls: 2 },
          events: {
            onReady: (event) => {
            },
            onStateChange: (event) => {
            }
          }
        });
      }
    }
  }

  dismissIntro(): void {
    if (this.intro === true) {
      this.intro = false;
      if (this.player) {
        this.player.setVolume(8);
        this.player.playVideo();
      }
    }
  }

  toggleLabel(): void {
    if (this.map.getMapTypeId() === google.maps.MapTypeId.SATELLITE) {
      this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
      this.labels = true;
    } else {
      this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      this.labels = false;
    }
  }

  showPosition(): string {
    if (this.map) {
      const center = this.map.getCenter();
      return `L${this.map.getZoom()} ${center.lat().toFixed(5)}, ${center.lng().toFixed(5)}`;
    } else {
      return '';
    }
  }

  timeEmoji(hours: number): string {
    let emoji = '';
    if (hours === 0) {
      emoji = '12AM';
    } else if (hours > 0 && hours < 12) {
      emoji = `${hours}AM`;
    } else if (hours === 12) {
      emoji = '12PM';
    } else if (hours > 12 && hours < 24) {
      emoji = `${hours - 12}PM`;
    }
    return `${emoji}`;
  }

  private detectMobile(): boolean {
    const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
    return regex.test(navigator.userAgent);
  }

  camera(): void {
    const center = this.map.getCenter();
    const latitude = center.lat();
    const longitude = center.lng();
    const zoom = this.map.getZoom();
    const heading = this.map.getHeading() ?? 0;
    this.latitude = latitude;
    this.longitude = longitude;
    this.zoom = zoom;
    let shareInfo;
    if (this.shareIndex && this.shareInfo &&
      latitude === this.shareInfo.latitude && longitude === this.shareInfo.longitude &&
      (zoom === this.shareInfo.zoom || zoom === this.shareInfo.zoom - 1)) {
      shareInfo = this.shareInfo;
    } else {
      shareInfo = undefined;
    }
    const dialogRef = this.dialog.open(HomeShareDialogComponent, {
      width: '500px',
      data: {
        latitude,
        longitude,
        zoom,
        heading,
        uid: this.user.uid,
        shareInfo,
      }
    });
    dialogRef.afterClosed().subscribe(async data => {
      const lastPhoto = this.cameraroll.get()[0];
      if (lastPhoto && lastPhoto.latitude === this.latitude && lastPhoto.longitude === this.longitude && lastPhoto.zoom === this.zoom) {
        this.shareInfo = lastPhoto;
        this.shareId = lastPhoto.id;
        this.shareIndex = 0;
      }
    });
  }

  switchStation(station: number): void {
    this.musicIndex = station;
    this.player.cueVideoById(this.music[this.musicIndex], 0);
    this.player.setVolume(8);
    this.player.playVideo();
  }

  selectPhoto(photo: Polaroid, index: number): void {
    if (photo) {
      this.latitude = photo.latitude;
      this.longitude = photo.longitude;
      this.zoom = photo.zoom;
      this.map.setCenter({
        lat: photo.latitude,
        lng: photo.longitude
      });
      this.map.setZoom(photo.zoom);
      google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        this.map.setZoom(photo.zoom);
        this.map.setHeading(photo.heading ?? 0);
      });
      this.shareInfo = photo;
      this.shareId = photo.id;
      this.shareIndex = index;
    }
  }

  showPhoto(): void {
    if (this.shareInfo) {
      this.intro = true;
    }
  }

  copyToClipboard(): void {
    this.common.copyToClipboard(`${window.location.protocol}//${window.location.host}/share/${this.shareId}`);
  }

  share(channel: string): void {
    this.common.share(channel, `${window.location.protocol}//${window.location.host}/share/${this.shareId}`);
  }

  deletePhoto(): void {
    if (this.shareIndex !== undefined) {
      this.cameraroll.delete(this.shareIndex);
      this.shareInfo = undefined;
      this.shareId = undefined;
      this.shareIndex = undefined;
    }
  }

  openHelp(): void {
    this.bottomSheet.open(BottomSheetHelpComponent);
  }

}
