import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'lofi-earth';

  clientVersion: string;
  offlineSnackbarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  showOfflineSnackbar(): void {
    this.offlineSnackbarRef = this.snackBar.open(
      'You appear to be offline. We will try to reconnect when we detect an internet connection.',
      'DISMISS',
      {
        duration: 24 * 60 * 60 * 1000,
        verticalPosition: 'bottom',
        panelClass: ['warning-snackbar']
      }
    );
  }

  @HostListener('window:offline', ['$event'])
  onOffline(event: Event): void {
    this.showOfflineSnackbar();
  }

  @HostListener('window:online', ['$event'])
  onOnline(event: Event): void {
    if (this.offlineSnackbarRef) {
      this.offlineSnackbarRef.dismiss();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    // tslint:disable-next-line: no-any
    this.httpClient.get('assets/version.json').subscribe((httpResponse: any) => {
      const serverVersion = httpResponse.version;
      if (this.clientVersion && serverVersion && this.clientVersion !== serverVersion) {
        const snackBarRef = this.snackBar.open('A new version is available.', 'UPDATE', {
          duration: 600000,
          verticalPosition: 'top'
        });
        snackBarRef.onAction().subscribe(() => {
          window.location.reload();
        });
      }
    });
  }

  constructor(
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if (!navigator.onLine) {
      this.showOfflineSnackbar();
    }

    // tslint:disable-next-line: no-any
    this.httpClient.get('assets/version.json').subscribe((httpResponse: any) => {
      this.clientVersion = httpResponse.version;
    });
  }
}
