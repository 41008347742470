<div [class]="intro ? 'intro visible' : 'intro hidden'"
    style="padding: 50px; cursor: pointer; background: rgba(0, 0, 0, 0.7); pointer-events: none;">
    <div class="tagline">
        Chill to lo-fi hip hop and let your curiosity roam the earth.
    </div>
    <div *ngIf="!shareId && intro" class="polaroid">
        <img src="/assets/images/lofi_earth.jpg" class="polaroid-photo">
        <div class="slogan">
            <div class="pulsating-circle" style="display: inline-block">
            </div>
        </div>
    </div>
    <div *ngIf="shareId && intro" class="polaroid">
        <img src="/assets/images/polaroid_black.jpg" class="polaroid-photo"
            style="position: fixed; object-fit: cover; z-index: -1">
        <img [src]="shareInfo?.image ? shareInfo?.image : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='"
            [class]="shareInfo?.image ? 'polaroid-photo visible' : 'polaroid-photo hidden'">
        <div class="slogan">
            <div class="pulsating-circle" style="display: inline-block">
            </div>
        </div>
    </div>
</div>
<div #map style="width: 100vw; height: 100vh; outline: none" (click)="dismissIntro()"></div>
<div class="musicplayer">
    <div style="width: 250px; height: 140px;  background: white; border-radius: 11px; overflow: hidden"
        [style.pointer-events]="intro ? 'none' : ''">
        <div id="ytPlayer"></div>
    </div>
    <div class="stations">
        <button mat-raised-button color="accent" class="station-button" (click)="switchStation(0)"
            [disabled]="intro">1</button>
        <button mat-raised-button color="accent" class="station-button" (click)="switchStation(1)"
            [disabled]="intro">2</button>
        <button mat-raised-button color="accent" class="station-button" (click)="switchStation(2)"
            [disabled]="intro">3</button>
        <button mat-raised-button color="accent" class="station-button" (click)="switchStation(3)"
            [disabled]="intro">4</button>
    </div>
</div>

<div class="tools">
    <button mat-mini-fab color="primary" class="tools-button" style="margin-top: 0px" [disabled]="intro"
        (click)="openHelp()">
        <mat-icon style="position: relative; bottom: 2px">help</mat-icon>
    </button>
    <button mat-mini-fab color="accent" (click)="toggleLabel()" [style.background]="labels ? '' : '#3c3c3c'"
        [style.color]="labels ? 'white' : '#5f5f5f'" class="tools-button" [disabled]="intro">
        <mat-icon style="position: relative; bottom: 1px">map</mat-icon>
    </button>
    <button mat-mini-fab color="accent" (click)="camera()" class="tools-button" [disabled]="intro">
        <mat-icon style="position: relative; bottom: 2px">camera_enhance</mat-icon>
    </button>
    <button mat-mini-fab color="accent" class="tools-button" [disabled]="intro || !cameraroll.get()?.length"
        [matMenuTriggerFor]="roll">
        <mat-icon style="position: relative; bottom: 2px">camera_roll</mat-icon>
    </button>
    <mat-menu #roll="matMenu" class="roll">
        <button *ngFor="let photo of cameraroll.get(); index as i" mat-menu-item
            style="height: 70px; padding: 0px; overflow:visible" (click)="selectPhoto(photo, i)">
            <div style="display: inline-block; width: 16px">
                <div class="chicklet"></div>
                <div class="chicklet"></div>
                <div class="chicklet"></div>
                <div class="chicklet"></div>
                <div class="chicklet"></div>
            </div>
            <div
                style="display: inline-block; width: 64px; height: 64px; overflow: hidden; position: relative; border-radius: 4px">
                <img class="rollimage" [src]="photo.image">
            </div>
            <div style="display: inline-block; width: 16px">
                <div class="chicklet"></div>
                <div class="chicklet"></div>
                <div class="chicklet"></div>
                <div class="chicklet"></div>
                <div class="chicklet"></div>
            </div>
        </button>
    </mat-menu>
    <button
        *ngIf="shareInfo && latitude === shareInfo.latitude && longitude === shareInfo.longitude && (zoom === shareInfo.zoom || zoom === shareInfo.zoom - 1)"
        mat-mini-fab color="primary" class="tools-button" [matMenuTriggerFor]="photo" [disabled]="intro">
        <div class="selectimage-div">
            <img class="selectimage" [src]="shareInfo.image">
        </div>
    </button>
    <mat-menu #photo="matMenu">
        <div class="tools-section">
            PHOTO
        </div>
        <button mat-menu-item (click)="showPhoto()">
            Show
        </button>
        <button *ngIf="shareIndex !== undefined" mat-menu-item (click)="deletePhoto()">
            Delete
        </button>
        <div class="tools-section">
            SHARE
        </div>
        <button mat-menu-item (click)="share('twitter')">
            Twitter
        </button>
        <button mat-menu-item (click)="share('facebook')">
            Facebook
        </button>
        <button mat-menu-item (click)="copyToClipboard()">
            Copy link
        </button>
    </mat-menu>
</div>