import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import firebase from 'firebase/app';

import { Country, CountryService } from './country.service';

export interface Breadcrumb {
  latitude: number;
  longitude: number;
  zoom: number;
}

export interface User {
  updated?: firebase.firestore.Timestamp;
  latitude: number;
  longitude: number;
  zoom: number;
  language: string;
  locale: string;
  hours: number;
  minutes: number;
  uid: string;
  country?: Country;
  isMobile?: boolean;
  breadcrumbs?: Breadcrumb[];
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: firebase.User | null = null;
  userRef: Subscription | undefined;

  users: User[];
  usersUnsubscribeRef: (() => void) | undefined;

  public heatmap;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private countryService: CountryService,
    private ngZone: NgZone,
  ) {
    this.heatmap = new google.maps.MVCArray([]);
    if (this.userRef) {
      this.userRef.unsubscribe();
    }
    this.userRef = this.afAuth.user.subscribe(async user => {
      this.user = user;

      if (this.usersUnsubscribeRef) {
        this.usersUnsubscribeRef();
      }
      this.usersUnsubscribeRef = this.afs.collection('users').ref
        .orderBy('updated', 'desc')
        .limit(100)
        .onSnapshot(querySnapshot => {
          this.ngZone.run(async () => {
            if (!querySnapshot.metadata.hasPendingWrites) {
              const users: User[] = [];
              querySnapshot.forEach(u => {
                const userData: User = u.data() as User;
                userData.country = this.countryService.get(userData.locale);
                users.push(userData);
              });
              this.users = users;
              this.updateHeatmap();
            }
          });
        });
    });
  }

  updateHeatmap(): void {
    const now = Date.now() / 1000;
    const data = [];
    for (const u of this.users) {
      if (u.breadcrumbs?.length) {
        const weight = Math.pow(2, Math.max((6 - (now - u.updated?.seconds ?? 0) / (60 * 60)), 0));
        for (const p of u.breadcrumbs) {
          if (weight > 1) {
            data.push({
              location: new google.maps.LatLng(+p.latitude.toPrecision(4), +p.longitude.toPrecision(4)),
              weight
            });
          }
        }
      }
    }
    if (this.heatmap.getLength() > 0) {
      const newBreadCrumbs = [];
      const oldBreadCrumbs = [];
      for (const d of data) {
        const exists = this.heatmap.getArray().find(p => p.location.lat() === d.location.lat() && p.location.lng() === d.location.lng());
        if (!exists) {
          newBreadCrumbs.push(d);
        }
      }
      for (const p of this.heatmap.getArray()) {
        const exists = data.find(d => p.location.lat() === d.location.lat() && p.location.lng() === d.location.lng());
        if (!exists) {
          oldBreadCrumbs.push(p);
        }
      }

      for (const np of newBreadCrumbs) {
        this.heatmap.push(np);
      }
      for (const op of oldBreadCrumbs) {
        const index = this.heatmap.getArray()
          .findIndex(p => p.location.lat() === op.location.lat() && p.location.lng() === op.location.lng());
        this.heatmap.removeAt(index);
      }
    }
    this.heatmap.clear();
    for (const d of data) {
      this.heatmap.push(d);
    }
  }

  getUsers(): User[] {
    return this.users;
  }
}
