<style>
    .logo {
        font-family: Sacramento;
        margin-bottom: -190px;
        position: relative;
        z-index: 1;
        color: white;
        font-size: min(calc(100vw * 0.22), 150px);
        line-height: min(calc(100vw * 0.22), 150px);
        padding: 20px;
        width: calc(100% - 40px);
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    .status-label {
        font-size: 13px;
        margin-top: 13px;
        position: relative;
        bottom: 2px;
        color: #448aff;
    }

    .share-label {
        font-size: 13px;
        margin-top: 13px;
        position: relative;
        bottom: 2px;
        opacity: 0.65;
    }

    .share-button {
        line-height: 30px;
        font-weight: 400;
    }

    .polaroid {
        position: fixed;
        background: black;
        opacity: 1.0;
        height: 65vw;
        max-height: min(425px, 65vh);
        width: 100%;
        max-width: min(450px, 70vw);
        z-index: 10;
    }

    .visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 1s linear;
    }

    .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 1s, opacity 1s linear;
    }
</style>
<div mat-dialog-content>
    <div [class]="shareId ? 'polaroid hidden' : 'polaroid visible'" [style.opacity]="opacity">
        <img src="/assets/images/polaroid_blank.png" style="width: 100%; height: 100%; object-fit: cover;">
    </div>
    <div id="preview" #preview style="max-height: 425px; height: 65vw; overflow: hidden">
        <div class="logo">
            lofi.earth
        </div>
        <div id="previewMap" #previewMap style="width: 100%; height: 450px; overflow: hidden; outline: none"></div>
    </div>
</div>
<div *ngIf="!shareId" class="status-label" style="color: #bcbcbc">Developing earth photo...</div>
<div *ngIf="shareId" class="share-label" style="font-weight: 500">Share earth photo</div>
<div mat-dialog-actions style="padding-top: 0px; min-height: 40px; padding-bottom: 21px;">
    <button mat-mini-fab color="primary" class="share-button" [disabled]="!shareId" (click)="share('twitter')"
        [style.background]="shareId ? '#4ea6e9' : ''">
        <img src="/assets/images/twitter.png" style="width: 23px; height: 23px">
    </button>
    <button mat-mini-fab color="primary" class="share-button" [disabled]="!shareId" (click)="share('facebook')"
        [style.background]="shareId ? '#38519a' : ''">
        <img src="/assets/images/facebook.png"
            style="width: 32px; height: 32px; position: relative; bottom: 4px; right: 4px">
    </button>
    <button mat-mini-fab color="primary" class="share-button" (click)="copyToClipboard()" [disabled]="!shareId">
        <mat-icon>link</mat-icon>
    </button>
    <span style="flex: 1 1 auto"></span>
    <button mat-button (click)="cancel()" style="opacity: 0.5">Cancel</button>
</div>